// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-cv-page-js": () => import("./../src/templates/cv-page.js" /* webpackChunkName: "component---src-templates-cv-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-people-page-js": () => import("./../src/templates/people-page.js" /* webpackChunkName: "component---src-templates-people-page-js" */),
  "component---src-templates-research-page-js": () => import("./../src/templates/research-page.js" /* webpackChunkName: "component---src-templates-research-page-js" */),
  "component---src-templates-publications-page-js": () => import("./../src/templates/publications-page.js" /* webpackChunkName: "component---src-templates-publications-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

